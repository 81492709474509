import React from 'react'

import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <p
    style={{color: 'white'}}>
This application is offered by haloheatmaps, which is solely responsible for its content. It is not sponsored or endorsed by Microsoft. This application uses the Halo® Game Data API. Halo © 2018 Microsoft Corporation. All rights reserved. Microsoft, Halo, and the Halo Logo are trademarks of the Microsoft group of companies.</p>
  </Layout>
)

export default IndexPage
